.selectorTrigger {
    width: 100%;
    min-height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 8px;
    background: var(--content1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.selectorTrigger > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 8px;
}
.overlayHeader svg,
.selectorTrigger svg,
.selector svg {
    font-size: 18px;
    color: var(--font3);
}

.selectorOverlay {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    left: 0;
    position: fixed;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
}

.overlayHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    background: var(--content1);
}
.overlayHeader h4 {
    width: 100%;
    text-align: center;
    margin: 0;
}

.overlayContent {
    min-height: calc(100vh - 30px);
    padding: 0px 16px;
}

.filterFormWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 16px;
}
.filterForm {
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: stretch;
    gap: 8px;
}

.selector {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.carList {
    height: calc(var(--vh, 1vh) * 100 - 111px);
    overflow-y: auto;
}
.carList::-webkit-scrollbar {
    width: 6px;
}
.carList::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.carList li {
    display: flex !important;
}
.carList li svg {
    color: var(--point);
    font-size: 16px;
}
