/* VIEW */
.manageForm {
    margin: 0;
}
.manageForm > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editBtn {
    padding: 0px 8px;
    border: 1px solid var(--font3);
    border-radius: 20px;
}

.manageForm > div > div:first-of-type label::after {
    display: none;
}
.manageForm > div > div:first-of-type {
    flex: none !important;
    padding: 0;
}
.manageForm label {
    font-family: "Pretendard-Bold" !important;
    font-size: 1.063rem !important;
    color: var(--font2) !important;
}

.manageForm > div > div:last-of-type {
    width: 100%;
    border-color: var(--line1) !important;
    font-size: 1.063rem !important;
    font-family: "Pretendard-Regular" !important;
    background-color: var(--content1) !important;
    text-align: right;
    flex: 1 1 0 !important;
}
.manageForm > div > div:last-of-type:not(:has(textarea)) {
    padding: 4px !important;
}

.manageFormInput {
    max-width: calc(100% - 120px);
    width: 100%;
    min-height: 50px;
    height: 100% !important;
    padding: 6px !important;
    border-color: var(--line1) !important;
    font-size: 1.063rem !important;
    font-family: "Pretendard-Regular" !important;
    color: var(--font1) !important;
    line-height: 37px;
    overflow-x: auto;
}
.manageForm input:not([type="radio"]) {
    width: 100%;
    min-height: 38px;
    height: auto;
    border-color: transparent;
    border-radius: 8px;
    font-size: 1.063rem;
    font-family: "Pretendard-Regular";
    color: var(--font1);
    background-color: var(--background);
}

.manageForm span:has(input:not([type="radio"])):not(:has(button)) {
    height: 38px;
    padding: 0 11px;
    border-radius: 4px;
    border-color: var(--line1);
}

.manageForm svg {
    color: var(--font3);
    font-size: 16px;
}

.manageForm > div:has(textarea:disabled),
.manageForm > div:has(.uploadWrapper) {
    flex-direction: column;
    align-items: start;
    padding: 8px 0;
}
.manageForm textarea {
    min-height: 50px;
    border: 1px solid #e1e7ef !important;
    border-radius: 8px;
    text-align: left;
    padding: 11px !important;
}

/* REGISTER & UPDATE */
.veticalManageForm > div {
    flex-direction: column;
    align-items: flex-start;
}
.veticalManageForm > div > div:last-of-type:not(:has(textarea, button)) {
    min-height: 50px !important;
    padding: 0 !important;
    text-align: left;
}
.veticalManageForm input:not([type="radio"]) {
    min-height: 50px !important;
}
.veticalManageForm input:not([type="radio"]):focus-within {
    border-color: var(--point);
}
.veticalManageForm > div > div:last-of-type:has(input:focus-within) {
    color: var(--point);
}

.btnSubmitWrapper {
    height: fit-content;
    padding: 16px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.5), #fff);
}
.btnSubmit {
    width: calc(100vw - 32px);
}
