.searchWrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 14px;
}

.searchFormWrapper {
    display: flex;
    width: 100%;
    min-height: 50px;
    align-items: center;
    border-top: 0.5px solid var(--line1);
}
.searchForm {
    min-height: 50px;
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 0.5px solid var(--line1);
    background: var(--content1);
}
.searchForm > span:first-of-type {
    display: flex;
    height: 100%;
    min-width: 120px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background-color: var(--content2);
}

.searchInput {
    width: 100%;
    height: calc(100% - 12px);
    margin: 6px;
    border-color: var(--line1);
    font-size: 1.063rem;
    font-family: "Pretendard-Regular";
    color: var(--font2);
}

.searchSelect {
    width: 100%;
    height: calc(100% - 12px);
    margin: 6px;
}

.searchSelect > div {
    height: 100% !important;
}

.searchDatePicker {
    width: 100%;
    height: calc(100% - 12px);
    margin: 6px;
}
.searchDatePicker div:not(:nth-of-type(2)) {
    height: 100%;
}
.datePicker {
    height: 100%;
    border-color: var(--line1);
    font-size: 1.063rem;
    font-family: "Pretendard-Regular";
    color: var(--font2);
}
.datePicker svg {
    color: var(--font3);
}

.searchCol {
    padding: 0 8px;
}
.checkboxWrapper {
    margin: 6px;
}
.checkboxWrapper label span {
    font-size: 1.063rem;
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .searchForm > span:first-of-type {
        min-width: 100px;
    }
}
