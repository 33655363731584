.searchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterBtn {
    padding: 0px 8px;
    border: 1px solid var(--font3);
    border-radius: 20px;
}
.filteredFilterBtn {
    padding: 0px 8px;
    border: 1px solid var(--point);
    border-radius: 20px;
}

.overlayHeader svg,
.selector svg {
    font-size: 18px;
    color: var(--font3);
}
.pointIcon svg {
    color: var(--point);
}

.selectorOverlay {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    left: 0;
    position: fixed;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
}

.selectorPopup > div:last-of-type {
    width: 100%;
    max-width: calc(100vw - 32px);
    margin: 16px;
    border-radius: 16px;
    box-shadow: 0px -2px 6px -1px rgba(0, 0, 0, 0.08);
}
.selectorContainer {
    min-height: 40vh;
    max-height: calc(var(--vh, 1vh) * 60);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.selectorContainer a,
.selectorContainer > div > div {
    border: none !important;
    border-radius: 16px;
    background-color: transparent !important;
}
.selectorContainer > div:last-of-type {
    overflow-y: auto;
}
.selectorContainer > div:last-of-type::-webkit-scrollbar {
    display: none;
}
.selectorContainer a {
    padding: 0;
}
.selectorContainer a > div {
    font-family: "Pretendard-Regular" !important;
    color: var(--font1);
    border: none;
    padding: 12px 0;
}
.selectorContainer a > div > div {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.selectorSearch {
    --background: #f4f7fe !important;
}

.overlayHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0 16px;
    position: relative;
    background: var(--content1);
}
.overlayHeader p {
    width: 100%;
    margin: 0;
    text-align: center;
}

.overlayContent {
    height: calc(100vh - 50.5px);
    padding: 16px;
    overflow-y: auto;
}

.searchFormWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding-bottom: 64px;
}
.searchForm {
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: stretch;
    gap: 8px;
}
.searchForm > div {
    width: 100%;
}
.searchInput {
    min-height: 50px !important;
    background-color: var(--background);
    border-color: transparent;
    border-radius: 8px;
}
.searchDatePicker {
    width: 100%;
    height: calc(100% - 12px);
    margin: 6px;
}
.searchDatePicker div:not(:nth-of-type(2)) {
    height: 100%;
}

.datePicker {
    height: 100%;
    border-color: var(--line1);
    font-size: 1.063rem;
    font-family: "Pretendard-Regular";
    color: var(--font2);
}
.datePicker svg {
    color: var(--font3);
}

.searchCol {
    padding: 0 8px;
}
.checkboxWrapper label span {
    font-size: 1.063rem;
}

.overlayFooter {
    width: 100%;
    padding: 16px;
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(rgba(255, 255, 255, 0.5), #fff);
}

.searchForm textarea {
    border: 1px solid #e1e7ef !important;
    border-radius: 8px;
    text-align: left;
    padding: 8px !important;
}

.selectorTrigger {
    height: 50px;
    display: flex;
    padding: 4px 11px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background);
}
