.markerWrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.wrap {
    position: absolute;
    text-align: center;
    font-family: "Pretendard-Bold", sans-serif;
    line-height: 1.3;
}
.wrap:has(.info) {
    bottom: 42px;
    min-width: 90px;
    height: 50px;
    overflow: hidden;
    font-size: 0.813rem;
}
.wrap * {
    padding: 0;
    margin: 0;
}
.info {
    width: 100%;
    border-bottom: 2px solid #ccc;
    border-right: 1px solid #ccc;
    background: #fff;
    text-align: center;
    padding: 0 5px;
}
.wrap .info {
    height: 38px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
}
.info:nth-child(1) {
    border: 0;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 2px 0px;
}
.wrap .info:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--point);
}
.info:after {
    content: "";
    position: absolute;
    margin-left: -12px;
    left: 50%;
    bottom: 0;
    width: 21px;
    height: 16px;
    background-image: url("../../assets/images/overlay_path.svg");
}
.text {
    height: fit-content;
    text-align: center;
    font-size: 0.938rem;
}
.info .text {
    padding: 0;
    color: white;
    font-weight: bold;
}
.space > div:nth-of-type(4) {
    position: relative;
}
.addressWrapper {
    position: absolute;
    top: 8px;
    left: 8px;
    text-align: center;
    background-color: white;
    line-height: 1.3;
    z-index: 2;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.addressWrapper .text {
    height: 100%;
    text-align: center;
    font-size: 0.938rem;
    margin: 0;
}

/* MARKER */
.marker {
    width: 35px;
    height: 43.17px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;
}
.markerDrive {
    background-image: url(../../assets/images/marker/marker_car_drive.png);
}
.markerDriveEnd {
    background-image: url(../../assets/images/marker/marker_car_drive_end.png);
}
.markerBattery {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 5px;
}
.badgeWrapper {
    top: -7px;
    left: 25px;
    position: absolute;
    display: flex;
    gap: 1px;
}
.badgeWrapper sup {
    height: 18px !important;
}
