.menuWrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 8px;
    padding-bottom: 60px;
}
.userWrapper,
.userWrapper div {
    margin: 0 !important;
}
