/* table */
.table > div > div > div > div > div:nth-last-child(1)::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
.table > div > div > div > div > div:nth-last-child(1)::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.table thead td {
    border-radius: 0 !important;
    background-color: var(--content2) !important;
}
.table tbody td {
    background-color: white !important;
    border-color: #dfe2e7 !important;
    min-width: 0 !important;
    width: max-content !important;
}
.table thead,
.table tr,
.table th {
    height: 50px;
}
.table th {
    height: 40px;
    width: fit-content;
    font-family: "Pretendard-Bold";
    font-size: 1.063rem;
    word-break: keep-all;
    color: var(--font2) !important;
    background-color: var(--content2) !important;
    white-space: nowrap;
}
.table thead th,
.table tbody td {
    padding: 0 8px !important;
    border-radius: 0 !important;
    border-bottom: 0.5px solid var(--line1);
    text-align: center !important;
}
.table th > div span:last-of-type {
    color: rgba(0, 18, 54, 0.5) !important;
    opacity: 0.7;
    background-color: white;
}
.table th::before {
    display: none;
}
.table td {
    font-family: "Pretendard-Regular";
    font-size: 1.063rem;
    color: var(--main-font2-color);
}

/* wrapper */
.wrapper {
    width: 100%;
    background-color: white;
    margin: 0;
}
.wrapper > div > div:first-child {
    background-color: var(--content2);
}
.wrapper > div > div:first-child > span {
    width: 100%;
    color: var(--main-font2-color);
    font-family: "Pretendard-Bold";
}
.wrapper > div > div:nth-child(2) {
    margin: 16px;
}
.wrapper > div > div:nth-child(2) > div {
    padding: 0 !important;
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .wrapper > div > div:nth-child(2) {
        margin: 16px 0;
    }
    .wrapper > div > div:nth-child(2) > div > div {
        position: relative;
    }
    .wrapper > div > div:nth-child(2) > div > div > div:last-of-type {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .wrapper > div > div:nth-child(2) > div > div > div:last-of-type > div {
        width: fit-content;
        overflow: hidden !important;
    }
    .wrapper th {
        width: fit-content !important;
        max-width: 50px;
    }
}
