/* Dashboard */
.dashboardWrapper {
    width: 100%;
    height: calc(100vh - 48px);
    overflow: hidden;
}
.mapWrapper {
    display: flex;
    position: relative;
}
.mapWrapper > div:last-of-type {
    width: 100%;
    border-radius: 8px;
}

.carSelectorOverlay {
    width: clamp(390px, 20vw, 400px);
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    z-index: 2;
    padding: 24px;
}
.carSelectorWrapper {
    border-radius: 8px;
    background: var(--content1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.carSelector {
    width: 100%;
    height: 48px !important;
}
.carSelector > div,
.carSelector > div:hover,
.carSelector > div:focus-within {
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.carInfoOverlay {
    width: 100%;
    display: flex;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
    background-color: white;
}
.carInfoOverlay > div,
.carInfoOverlay > div > div {
    width: 100% !important;
    align-items: center !important;
}
.carInfoButton {
    height: 40px;
    border: 0;
    color: var(--font3);
    gap: 8px;
}
.carInfoButton img {
    transform: rotate(45deg);
}
.showCarInfoButton {
    width: 40px;
    min-width: auto !important;
    border-radius: 0 4px 4px 0 !important;
}
.showCarInfoButton img {
    transform: rotate(0deg);
}

.doorButton,
.doorButton > div,
.doorButton button {
    width: 100%;
    height: fit-content;
}
.doorButton button {
    color: var(--font1);
    background: #f6f8fd !important;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.doorButton button:hover {
    color: var(--point) !important;
    background: #f6f8fd !important;
    filter: none !important;
    border: none;
}
.doorButton div:first-of-type button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0.5px solid #dfe2e8 !important;
}
.doorButton div:last-of-type button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0.5px solid #dfe2e8 !important;
}
.doorButton button > span {
    line-height: initial;
}

.mapSegmented {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    background-color: var(--content1) !important;
}

.refreshBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    padding: 4px 10px;
    cursor: pointer;
}
.refreshBtnWrapper:hover {
    filter: brightness(1.2);
}
.refreshBtn {
    width: 32px !important;
    min-width: auto !important;
    height: 32px !important;
    border: none !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.refreshIcon {
    color: var(--font1);
}
.loadingRefreshIcon {
    color: var(--point);
    animation: rotate 0.5s;
}
@keyframes rotate {
    0% {
        transform: rotate(-360deg);
    }
}

/* DashboardSummary */
.summaryWrapper {
    width: 100%;
    display: flex;
    gap: 24px;
}
.summary {
    width: 100%;
    height: 96px;
    padding: 24px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--content1);
}
.summaryTextWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}
.summaryTextWrapper > div {
    display: flex;
    gap: 2px;
    align-items: center;
}
.summaryTextWrapper > div img {
    margin-top: 2px;
}
.summaryTextWrapper h3 {
    margin: 0;
    line-height: 18px;
}
.summaryTextWrapper p {
    margin: 0;
}

.statistic > div,
.statistic > div > span {
    font-family: "Pretendard-Regular", sans-serif;
    font-size: 1.063rem;
    height: 40px;
}
.statistic > div > span > span {
    font-size: 40px;
    font-family: "ds-digital", sans-serif;
    font-weight: bold;
    color: #1a73e9;
    line-height: 40px;
}
@media (max-width: 1250px) {
    .statistic > div,
    .statistic > div > span {
        font-size: 0.938rem;
    }

    .statistic > div > span > span {
        font-size: 30px;
    }
}

/* DashboardFilter */
.filterOverlay {
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 2;
    padding: 24px;
}
.filterWrapper {
    width: fit-content;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--content1);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.filter {
    display: flex;
    min-width: 74px;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    opacity: 0.4;
    filter: saturate(0);
    border-radius: 8px;
}
.filter:hover {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}
.filter:active {
    box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.filter::after {
    display: none;
}
.filter > span:first-of-type {
    display: none;
}
.filter > span:last-of-type {
    font-family: "Pretendard-Bold";
    font-size: 1.063rem;
    color: var(--font1);
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px;
}
.checkedFilter {
    opacity: 1;
    filter: none;
}

.dividerWrapper > div {
    height: fit-content;
    display: flex;
}

/* DashboardCarInfo */
.carInfoWrapper {
    width: 100%;
    max-height: 100%;
}
.carInfoSpace {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
}
.carInfoSpace > span:last-of-type {
    cursor: pointer;
}
.carInfoAlarmSpace {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.carInfo {
    width: 100%;
    display: flex;
    gap: 0px;
    flex-direction: column;
}
.batteryWrapper {
    width: 100%;
    height: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

/* DashboardDrawer */
/* .drawerWrapper > div > div {
    overflow: hidden !important;
} */
.drawerWrapper > div > div::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
.drawerWrapper > div > div::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.drawerBtn {
    width: 70px !important;
    height: 30px !important;
    position: absolute !important;
    left: calc(50% - 35px);
    top: -29px;
    line-height: 30px;
    background-color: white;
    border-radius: 4px 4px 0 0 !important;
    border: none;
    padding: 0 !important;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drawerBtn:hover img {
    filter: brightness(1.2);
}
.drawerStatsCol:first-of-type {
    padding-right: 12px;
}
.drawerStatsCol:last-of-type {
    padding-left: 12px;
}
.drawerStatsRow,
.drawerStatsCol {
    height: 100% !important;
    margin: 0 !important;
}
.drawerStatsCol > div {
    margin: 0 !important;
}
