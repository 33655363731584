/* Dashboard */
.mapWrapper {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.carSelectorWrapper {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    z-index: 3;
    padding: 16px;
}

.carInfoOverlay {
    width: 100%;
    display: flex;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
    background-color: white;
}
.carInfoOverlay > div,
.carInfoOverlay > div > div {
    width: 100% !important;
    align-items: center !important;
}
.carInfoButton {
    height: 40px;
    border: 0;
    color: var(--font3);
    gap: 8px;
}
.carInfoButton img {
    transform: rotate(45deg);
}
.showCarInfoButton {
    width: 40px;
    min-width: auto !important;
    border-radius: 0 4px 4px 0 !important;
}
.showCarInfoButton img {
    transform: rotate(0deg);
}

.doorButton,
.doorButton > div,
.doorButton button {
    width: 100%;
    height: fit-content;
}
.doorButton button {
    color: var(--font1);
    background: #f6f8fd !important;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.doorButton button:hover {
    color: var(--point) !important;
    background: #f6f8fd !important;
    filter: none !important;
    border: none;
}
.doorButton div:first-of-type button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0.5px solid #dfe2e8 !important;
}
.doorButton div:last-of-type button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0.5px solid #dfe2e8 !important;
}
.doorButton button > span {
    line-height: initial;
}

.refreshBtnWrapper {
    display: flex;
    gap: 4px;
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 3;
    padding: 4px 8px;
    cursor: pointer;
}
.refreshBtnWrapper:hover {
    filter: brightness(1.2);
}
.refreshBtn {
    height: 32px !important;
    width: 32px;
    border: none !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.refreshIcon {
    color: var(--font2);
}
.loadingRefreshIcon {
    color: var(--point);
    animation: rotate 0.5s;
}
@keyframes rotate {
    0% {
        transform: rotate(-360deg);
    }
}

/* DashboardFilter */
.filterOverlay {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 62px;
    z-index: 2;
    padding: 16px;
    overflow-y: auto;
}
.filterOverlay::-webkit-scrollbar {
    display: none;
}

.filter {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--content1);
}
.filter::after {
    display: none;
}
.filter > span:first-of-type {
    display: none;
}
.filter img,
.filter > span:last-of-type > span {
    font-family: "Pretendard-Bold", sans-serif;
    font-size: 1.063rem;
    opacity: 0.6;
    filter: saturate(0);
}
.filter > span:last-of-type {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px;
}
.checkedFilter img,
.checkedFilter > span:last-of-type > span {
    opacity: 1;
    filter: none;
}

/* DashboardCarInfo */
.carInfoPanel {
    bottom: 60px;
}
.carInfoWrapper {
    padding: 24px 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}
.carInfoAlarmSpace {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.carInfo {
    width: 100%;
    display: flex;
    gap: 4px;
    align-items: center;
}
.carInfo > span:first-of-type {
    min-width: 60px;
}
.carInfo svg {
    font-size: 16px;
    color: var(--font3);
}
.alarmBtn {
    padding: 0px 8px;
    border: 1px solid var(--font3);
    border-radius: 20px;
}
.batteryWrapper {
    width: 100%;
    height: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.drawerWrapper > div > div::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
.drawerWrapper > div > div::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.drawerBtn {
    width: 70px !important;
    height: 30px !important;
    position: absolute !important;
    left: calc(50% - 35px);
    top: -29px;
    line-height: 30px;
    background-color: white;
    border-radius: 4px 4px 0 0 !important;
    border: none;
    padding: 0 !important;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drawerBtn:hover img {
    filter: brightness(1.2);
}
.drawerStatsCol:first-of-type {
    padding-right: 12px;
}
.drawerStatsCol:last-of-type {
    padding-left: 12px;
}
.drawerStatsRow,
.drawerStatsCol {
    height: 100% !important;
    margin: 0 !important;
}
.drawerStatsCol > div {
    margin: 0 !important;
}
