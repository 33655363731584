.manageFormWrapper {
    border-top: 0.5px solid var(--line1);
}
.manageForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
}
.manageForm > div {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-bottom: 0.5px solid var(--line1);
    height: 100%;
}

.manageForm > div > div:first-of-type {
    display: flex;
    width: 120px;
    min-height: 50px;
    align-self: stretch;
    align-items: center;
    text-align: center !important;
    justify-content: center;
    background-color: var(--content2);
}
.manageForm > div > div:first-of-type label {
    font-family: "Pretendard-Bold";
    font-size: 1.063rem !important;
    color: var(--font2) !important;
    white-space: initial;
    word-break: keep-all;
    line-height: 20px;
}
.manageForm > div > div:first-of-type label::after {
    display: none;
}

.manageForm > div > div:last-of-type {
    max-width: calc(100% - 120px);
    width: 100%;
    padding: 6px !important;
    border-color: var(--line1) !important;
    font-size: 1.063rem !important;
    font-family: "Pretendard-Regular" !important;
    background-color: var(--content1) !important;
}
.manageFormInput {
    max-width: calc(100% - 120px);
    width: 100%;
    min-height: 50px;
    height: 100% !important;
    padding: 6px !important;
    border-color: var(--line1) !important;
    font-size: 1.063rem !important;
    font-family: "Pretendard-Regular" !important;
    color: var(--font1) !important;
    line-height: 37px;
}
.manageForm > div > div:last-of-type input:not([type="radio"]) {
    width: 100%;
    height: 38px;
    border-color: var(--line1);
    border-radius: 4px;
    font-size: 1.063rem;
    font-family: "Pretendard-Regular";
    color: var(--font1);
}

.manageForm span:has(input:not([type="radio"])) {
    height: 38px;
    padding: 0 11px;
    border-radius: 4px;
    border-color: var(--line1);
}
.manageForm span:has(input) input:not([type="radio"]) {
    height: auto !important;
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .manageForm > div > div:first-of-type {
        width: 100px;
        flex: none !important;
    }
    .manageForm > div > div:last-of-type {
        width: auto;
        flex: 1 1 0 !important;
    }
}
