.layout {
    height: calc(var(--vh, 1vh) * 100);
    background-color: white;
}

.header {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    background: transparent;
}
.headerTitle {
    width: 100%;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
}
.header h4 {
    margin: 0;
}
.header svg {
    font-size: 18px;
}
.header .menuIcon {
    color: var(--font3);
}
.header svg:focus {
    filter: brightness(0.7);
}
.headerTitle svg {
    font-size: 16px;
}
.buttonWrapper {
    width: 62px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.leftButtonWrapper {
    left: 0;
}
.rightButtonWrapper {
    right: 0;
}
.dummyIcon {
    width: 18px;
    min-width: 18px;
    height: 18px;
}

.content {
    padding: 0 16px 16px 16px;
    overflow-x: hidden;
}
.content::-webkit-scrollbar {
    width: 6px;
}
.content::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.footer {
    width: 100vw;
    position: absolute;
    bottom: 0;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.16);
    background: var(--content1);
    z-index: 2;
}
.footer > div {
    min-height: 60px;
}
