.tableWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex: 0 1 auto;
    gap: 8px;
    background-color: white !important;
}
.tableTitleWrapper {
    display: flex;
    gap: 8px;
    flex: 1 0 0;
}

.table {
    width: 100%;
    height: 100%;
}
.table table {
    border-radius: 0 !important;
}
.table thead,
.table tr,
.table th {
    height: 50px;
}
.table tr:hover td {
    background-color: white !important;
}
.table th,
.table td {
    height: 50px;
    padding: 0 8px !important;
    border-radius: 0 !important;
    border-bottom: 0.5px solid var(--line1) !important;
    background-color: white !important;
}
.table th {
    height: 40px;
    font-family: "Pretendard-Bold";
    font-size: 1.063rem;
    word-break: keep-all;
    color: var(--font2) !important;
    background-color: var(--content2) !important;
    white-space: nowrap;
    text-align: center !important;
}
.table th span,
.table td {
    text-align: center;
}
.table th span:last-of-type {
    color: rgba(0, 18, 54, 0.5) !important;
    opacity: 0.7;
}
.table th::before {
    display: none;
}
.table td {
    font-family: "Pretendard-Regular";
    font-size: 1.063rem;
    color: var(--main-font2-color);
}
.table td img {
    margin-top: 8px;
}

.pointerTable .selectedRow {
    background-color: rgba(93, 160, 227, 0.2) !important;
}
.pointerTable tr:hover td {
    background-color: rgba(93, 160, 227, 0.07) !important;
}

.pointerTable td {
    cursor: pointer;
    background-color: transparent !important;
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .table > div > div > div:first-of-type {
        overflow-x: auto;
    }
    .table > div > div > div:first-of-type::-webkit-scrollbar {
        width: 6px;
    }
    .table > div > div > div:first-of-type::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .pointerTable .selectedRow td {
        background-color: rgba(93, 160, 227, 0.2) !important;
    }
}
