.layout {
    height: 100vh;
}

.sider {
    height: 100vh;
    background-color: transparent !important;
    padding: 24px !important;
}
.sider > div {
    height: 100%;
    background-color: white !important;
    border-radius: 8px;
}

.content {
    padding: 24px 24px 24px 0;
    overflow-y: scroll;
    overflow-x: hidden;
}
.content::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
.content::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.footer {
    background-color: white;
}
