@font-face {
    font-family: "ds-digital";
    src: url(../../assets/fonts/DS-DIGIB.TTF);
}

.card {
    width: 100%;
    min-height: 200px;
    height: 100%;
    border: none !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card > div {
    width: 100%;
    height: 100%;
    padding: 0 !important;
}
.spinWrapper {
    height: calc(100% - 73px);
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card p,
.card #SvgjsText1239 {
    font-size: 1.063rem;
    padding-left: 10px;
}
.statistic > div,
.statistic > div > span {
    font-family: "Pretendard-Bold", sans-serif;
    font-size: 1.063rem;
    height: 40px;
}

.statistic > div > span > span {
    font-size: 40px;
    font-family: "ds-digital", sans-serif;
    font-weight: bold;
    margin-right: 10px;
    color: #1a73e9;
    line-height: 40px;
}

.icon > span {
    margin-top: 6px;
    font-size: 40px;
    color: #aeb3bc;
}

.checkbox span {
    font-size: 1.063rem;
}

@media (max-width: 1250px) {
    .card > div {
        padding: 10px;
    }

    .card p,
    .card #SvgjsText1239,
    .statistic > div,
    .statistic > div > span {
        font-size: 0.938rem;
    }

    .statistic > div > span > span {
        font-size: 30px;
    }

    .icon > span {
        margin-top: 0;
        font-size: 30px;
        line-height: 30px;
    }
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .wrapper > div > div:nth-child(2) > div > div > div:last-of-type {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
