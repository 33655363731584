@font-face {
    font-family: "Pretendard-Regular";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "Pretendard-Bold";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff") format("woff");
    font-weight: 700;
}

:root {
    --vh: 100%;
    --font1: #191919;
    --font2: rgba(25, 25, 25, 0.6);
    --font3: #b5bfcd;

    --content1: #ffffff;
    --content2: #eaeff7;
    --background: #f4f7fe;
    --line1: #dde2ee;
    --line2: #a3aed0;

    --danger: #e0241a;
    --warning: #ffd233;
    --success: #12d965;
    --point: #1874ff;
}

body {
    margin: 0;
    font-family: "Pretendard-Bold", sans-serif;
    background: var(--content1);
    color: var(--font1);
    overflow: hidden !important;
    width: 100% !important;
    background: url(./assets/images/background.jpg);
    background-size: cover;
    background-position: bottom;
}

.ant-layout {
    background: transparent;
}

.pageWrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--content1);
}

.titleWrapper {
    display: flex;
    height: 32px;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

/* FONT */
* {
    font-style: normal;
    letter-spacing: 0;
    line-height: 150%;
}
h1,
h2,
h3,
h4 {
    font-family: "Pretendard-Bold", sans-serif;
    font-weight: 700;
    color: var(--font1);
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.5rem;
}
h4 {
    font-size: 1.25rem;
}

.fw-bd {
    font-family: "Pretendard-Bold" !important;
    color: var(--font1);
}
.fw-rg {
    font-family: "Pretendard-Regular" !important;
    color: var(--font1);
}

.fs-lg {
    font-size: 1.188rem;
}
.fs-md {
    font-size: 1.063rem;
}
.fs-sm {
    font-size: 0.938rem !important;
}
.fs-xs {
    font-size: 0.813rem !important;
}

.fc-point {
    color: var(--point);
}
.fc-font1 {
    color: var(--font1) !important;
}
.fc-font2 {
    color: var(--font2) !important;
}
.fc-font3 {
    color: var(--font3) !important;
}
.fc-danger {
    color: var(--danger) !important;
}
.ant-form-item-explain-error {
    font-family: "Pretendard-Regular";
    color: var(--danger) !important;
    font-size: 0.938rem;
}
.link-text {
    color: var(--point) !important;
    text-decoration: underline;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    transform: skew(-0.1deg);
}

/* BUTTON */
button:not(
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn,
        .ant-picker-month-btn,
        .ant-picker-year-btn,
        .ant-picker-decade-btn,
        .ant-pagination-item-link,
        .ant-btn-icon-only,
        .notBtn,
        .react-datepicker__navigation
    ) {
    height: 40px;
    line-height: 32px !important;
    min-width: 130px;
    font-family: "Pretendard-Bold" !important;
    font-size: 1.063rem;
    border-radius: 4px;
    display: flex;
    padding: 8px 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
}
button:not(
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn,
        .ant-picker-month-btn,
        .ant-picker-year-btn,
        .ant-picker-decade-btn,
        .ant-pagination-item-link,
        .ant-btn-icon-only,
        .notBtn,
        :disabled
    ):hover {
    filter: brightness(1.2);
}
.btn-text:hover {
    opacity: 0.8;
}
#__react-kakao-maps-sdk___Map button {
    min-width: auto !important;
}
.dashboardMap > div:last-of-type {
    left: -21px !important;
    top: 21px !important;
}
.btn-icon {
    cursor: pointer;
}
.btn-icon:hover {
    filter: brightness(0.8);
}
.ant-modal-confirm-btns button {
    line-height: inherit !important;
    display: inline-block;
}

.ant-btn > .anticon + span {
    margin: 0;
}
.ant-btn-primary,
.btn-primary,
.ant-btn-primary:hover,
.btn-primary:hover,
:where(.css-dev-only-do-not-override-3mqfnx).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--point) !important;
    color: var(--content1) !important;
    border: none !important;
    box-shadow: none;
}

.btn-primary:disabled {
    background: var(--point);
    color: var(--content1) !important;
    border: none !important;
    opacity: 0.5;
    filter: saturate(80%);
}
.ant-modal-confirm-btns .ant-btn-default,
.ant-btn-secondary,
.btn-secondary {
    background: var(--content1);
    color: var(--point) !important;
    border: 1px solid var(--point) !important;
}
.btn-secondary:disabled {
    background: var(--content1);
    color: var(--point) !important;
    border: 1px solid var(--point) !important;
    opacity: 0.5;
}
.btn-secondary-danger {
    background: var(--secondary);
    color: var(--danger) !important;
    border: none !important;
}
.btn-secondary-danger:disabled {
    background: var(--secondary);
    color: var(--danger) !important;
    border: none !important;
    opacity: 0.5;
}
.btn-danger {
    background: var(--secondary);
    color: var(--danger) !important;
    border: 1px solid var(--danger) !important;
}
.btn-danger:disabled {
    background: var(--secondary);
    color: var(--danger) !important;
    border: 1px solid var(--danger) !important;
    opacity: 0.5;
}

/* INPUT & SELECT */
input,
.ant-input,
.ant-select-selection-item,
.ant-select-item-option-content {
    font-family: "Pretendard-Regular" !important;
    color: var(--font1) !important;
    font-size: 1.063rem !important;
}
input:-internal-autofill-selected {
    background-color: transparent !important;
}
input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled {
    color: var(--font2) !important;
    background-color: #e1e7ef;
}
.ant-picker,
.ant-select {
    height: 38px !important;
}
.ant-picker,
.ant-select-selector {
    border-radius: 4px !important;
    border-color: var(--line1) !important;
}

.ant-input-affix-wrapper {
    border: 1px solid var(--line1);
    border-radius: 8px;
}
.ant-input-affix-wrapper:hover,
.ant-select-selector:hover {
    border: 1.5px solid var(--point) !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
.ant-select-focused .ant-select-selector {
    border: 1.5px solid var(--point) !important;
    box-shadow: 0px 0px 3px 0px #1874ff;
}
.ant-input-affix-wrapper svg {
    color: var(--line2);
}
.ant-select-selection-search {
    padding: 0 !important;
}
.ant-select-selection-placeholder {
    font-family: "Pretendard-Regular";
    font-size: 1.063rem;
    line-height: 34px !important;
}
.ant-select-selection-search-input {
    min-height: 34px !important;
}
textarea::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
textarea::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.disabled-input,
.disabled-selector > div,
.disabled-selector > div:hover,
.disabled-datepicker {
    color: var(--font1) !important;
    font-size: 1.063rem;
    border-color: transparent !important;
    background-color: transparent !important;
    cursor: default !important;
}
.disabled-input.fc-danger {
    color: var(--danger) !important;
}
.disabled-input.fc-point {
    color: var(--point) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    cursor: default !important;
}
.disabled-selector .ant-select-arrow,
.disabled-selector .ant-select-selection-placeholder,
.disabled-datepicker svg,
.disabled-radio > label:not(.ant-radio-wrapper-checked),
.disabled-radio .ant-radio-checked {
    display: none !important;
}
.disabled-datepicker input {
    min-width: 200px !important;
}
.disabled-datepicker input::placeholder {
    color: transparent !important;
}
.disabled-radio label > .ant-radio-checked + span {
    color: var(--font1) !important;
    padding: 0;
}

/* MODAL */
.ant-modal:not(.custom-modal),
.ant-modal-confirm-confirm {
    width: clamp(400px, 30vw, 500px) !important;
}
.ant-modal:has(.ant-col-12) {
    min-width: 680px !important;
}
.ant-modal-header {
    background: var(--content1) !important;
}
.ant-modal-content {
    background: var(--content1) !important;
    border-radius: 20px !important;
    padding: 24px !important;
}
.ant-modal-confirm-title {
    color: var(--font1) !important;
    font-family: "Pretendard-Bold" !important;
}
.ant-modal-confirm-title {
    font-size: 1.188rem !important;
}
.ant-modal-confirm-content {
    font-family: "Pretendard-Regular";
    font-size: 1.063rem !important;
    text-align: center;
}
.ant-modal-confirm-body:has(svg) .ant-modal-confirm-content {
    margin-right: 34px;
}
.ant-modal-confirm-btns {
    text-align: center !important;
}

/* MENU */
.ant-menu:not(.ant-menu-sub) {
    max-height: 54.5vh;
    overflow-y: auto;
    display: grid;
    gap: 8px;
}
.ant-menu::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}
.ant-menu::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.ant-menu-item,
.ant-menu-submenu-title {
    width: 100% !important;
    min-height: 54px;
    display: flex;
    gap: 16px;
    align-items: center;
    align-self: stretch;
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 16px !important;
    margin: 0 !important;
}
.ant-menu-title-content {
    margin: 0 !important;
}
.ant-menu-title-content a,
.ant-menu-title-content p,
.ant-menu-submenu-title span,
.ant-menu-title-content div {
    font-family: "Pretendard-Bold";
    font-size: 1.063rem;
    font-style: normal;
    line-height: 100%;
    margin: 0;
}
.ant-menu-title-content a,
.ant-menu-title-content p,
.ant-menu-submenu-title span,
.ant-menu-title-content div:not(.fc-danger) {
    color: var(--font2) !important;
}
.ant-menu-item-only-child a,
.ant-menu-title-content div {
    margin-left: 10px;
}
.ant-menu-title-content div:hover {
    filter: saturate(0.8);
}
.ant-menu-item-selected .ant-menu-title-content p,
.ant-menu-submenu-selected .ant-menu-submenu-title span {
    color: var(--font1) !important;
}

.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
}
.ant-menu-inline .ant-menu-sub.ant-menu-inline li {
    padding-left: 52px !important;
}

.menuIconWrapper {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background: var(--content2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-menu-item-selected .menuIconWrapper,
.ant-menu-submenu-selected .menuIconWrapper {
    background: var(--point) !important;
}
.ant-menu-item-selected img,
.ant-menu-submenu-selected img {
    filter: brightness(135%) !important;
}
.ant-menu-submenu-arrow {
    color: var(--font3) !important;
}
.ant-layout-sider-trigger {
    display: none;
}

/* CHART */
.apexcharts-canvas {
    position: static !important;
}
.apexcharts-canvas svg {
    fill: var(--gray3) !important;
}
.apexcharts-canvas .apexcharts-toolbar > div:hover svg {
    fill: var(--gray2) !important;
}
.apexcharts-xaxis-annotations line {
    stroke: var(--point);
}
.apexcharts-xaxis-annotations rect {
    stroke: var(--font1);
    fill: var(--content1);
}
.apexcharts-xaxis-annotations text {
    fill: var(--font1);
}
.apexcharts-legend {
    max-height: 44px !important;
    overflow-y: auto;
}

/* CHECKBOX */
.ant-checkbox-inner {
    border-radius: 2px !important;
    background: transparent !important;
}
.ant-checkbox-inner::after {
    border-width: 3px;
    border-color: var(--content1) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background: var(--point) !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border: 1px solid var(--font2);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--point);
}

.iconCheckbox {
    height: 32px;
    border-radius: 20px;
    padding: 4px 2px;
    border: solid 1px var(--content1);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.iconCheckbox:hover {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}
.iconCheckbox:active {
    box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.iconCheckbox > span:first-of-type {
    display: none !important;
}
.iconCheckbox > span:last-of-type {
    gap: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    opacity: 0.4;
    filter: saturate(0);
}
.iconCheckbox:has(.ant-checkbox-checked) > span:last-of-type {
    opacity: 1;
    filter: none;
}

/* BREADCRUMB */
.ant-breadcrumb li,
.ant-breadcrumb-separator {
    color: var(--font2, #a3aed0) !important;
    font-family: "Pretendard-Regular";
    font-size: 0.938rem;
}
.ant-breadcrumb li:last-child {
    color: var(--font1) !important;
    font-family: "Pretendard-Regular";
    font-size: 0.938rem;
}

/* SEGMENTED & RADIO */
.ant-radio + span {
    font-size: 1.063rem !important;
}
.ant-radio-group {
    padding: 0 8px;
}
.ant-segmented:not(.mapSegmented) {
    font-family: "Pretendard-Regular";
    font-size: 1.063rem;
    background: var(--content1) !important;
    border-radius: 0 !important;
    padding: 0;
}
.ant-segmented:not(.mapSegmented) .ant-segmented-item {
    background: var(--content1) !important;
    color: var(--font1) !important;
    border-radius: 0 !important;
    padding: 2px 12px;
}
.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked span,
.ant-segmented:not(.mapSegmented) .ant-segmented-item-selected {
    border-bottom: 2px solid var(--point) !important;
}
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked),
.ant-segmented:not(.mapSegmented) .ant-segmented-item:not(.ant-segmented-item-selected, :hover) {
    border: 0 !important;
    opacity: 0.6;
    border-bottom: 2px solid var(--content1) !important;
}
.ant-segmented:not(.mapSegmented) .ant-segmented-thumb {
    background-color: transparent !important;
    border-bottom: 2px solid var(--point) !important;
    opacity: 1 !important;
    z-index: 1;
}

.mapSegmented {
    font-size: 0.938rem;
    border-radius: 2px;
}
.mapSegmented .ant-segmented-item {
    border-radius: 4px !important;
}
.mapSegmented .ant-segmented-item:not(.ant-segmented-item-selected) {
    background-color: var(--content1) !important;
    color: var(--font1) !important;
}
.mapSegmented .ant-segmented-item-selected {
    padding: 0;
    background-color: var(--point) !important;
    color: var(--content1) !important;
}

.ant-radio-button-wrapper span {
    font-family: "Pretendard-Bold";
    font-size: 0.938rem;
}
.ant-radio-button-wrapper::before {
    display: none !important;
}
.ant-radio-button-wrapper:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.ant-radio-button-wrapper:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* TABS */
.ant-tabs-nav {
    margin-bottom: 0 !important;
}
.ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
}
.ant-tabs-tab {
    width: 100%;
    min-height: 42px;
    justify-content: center;
    margin: 0 !important;
    background-color: rgba(181, 191, 205, 0.4) !important;
    border: 0 !important;
    padding: 0 !important;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.ant-tabs-nav {
    max-height: 42px;
}
.ant-tabs-tab-active {
    background-color: white !important;
}
.ant-tabs-tab > div {
    font-family: "Pretendard-Bold";
    font-size: 1.063rem;
    color: var(--font1);
}
.ant-tabs-tab-active > div {
    color: var(--point) !important;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, :hover) {
    opacity: 0.7;
}
.ant-tabs-tab-disabled,
.ant-tabs-tab-disabled:hover {
    opacity: 0.3 !important;
}
.ant-tabs-content-holder {
    padding: 24px;
    background-color: white;
    border-radius: 0 0 8px 8px;
}
.ant-tabs-nav-operations {
    display: none !important;
}

/* TABLE */
.ant-table-header,
.ant-table-container {
    border-radius: 0 !important;
}
.ant-table-body {
    overflow-y: auto !important;
    background-color: var(--content1);
}
.ant-table-body::-webkit-scrollbar {
    width: 6px;
    background-color: #e5e5e5;
}
.ant-table-body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
.ant-table-cell-scrollbar {
    box-shadow: none !important;
}
tr:has(.ant-empty) {
    background: transparent !important;
}
.ant-pagination {
    margin-bottom: 0 !important;
}

.ant-table-wrapper .ant-empty,
.ant-table-wrapper .ant-spin-spinning {
    min-height: 30vh;
    max-height: none !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ant-table-wrapper:has(.ant-spin-spinning) .ant-empty,
.ant-table-wrapper .ant-spin-spinning {
    min-height: 70vh !important;
}
.ant-table-wrapper .ant-spin-spinning > span {
    width: 2.5rem;
    height: 2.5rem;
}
.ant-table-wrapper .ant-spin-spinning i {
    width: 18px;
    height: 18px;
}
.ant-table-wrapper:has(.ant-spin-spinning) .ant-empty > div {
    opacity: 0;
}

/* TRANSFER */
.ant-transfer-list {
    color: var(--font1) !important;
    background: var(--content2);
    border: 1px solid !important;
    border-image: radial-gradient(#ffffff, #ffffff20) !important;
    border-image-slice: 1 !important;
}
.ant-transfer-list-content-item-checked,
.ant-transfer-list-header {
    color: var(--font1) !important;
    background: transparent !important;
}
.ant-transfer-operation button {
    background: var(--secondary);
    color: var(--point) !important;
    border: none !important;
}
.ant-transfer-operation button:disabled {
    background: var(--secondary);
    color: var(--point) !important;
    border: none !important;
    opacity: 0.5;
}

/* CAROUSEL */
.slick-arrow {
    z-index: 1;
    display: grid !important;
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 20px !important;
    width: fit-content !important;
}
.slick-arrow:hover {
    color: #fff !important;
}
.ant-carousel .slick-prev {
    left: -8px;
}
.ant-carousel .slick-next {
    right: -8px;
}
.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
    content: none;
}
.ant-carousel .slick-dots li button {
    background-color: #000 !important;
    z-index: 1;
}

/* POPCONFIRM */
.ant-popconfirm {
    --antd-arrow-background: var(--content2) !important;
}
.ant-popover-inner {
    background: var(--content2) !important;
}
.ant-popconfirm-title {
    font-family: "Pretendard-Bold";
    color: var(--font1) !important;
    font-size: 1.063rem;
}
.ant-popconfirm-description {
    color: var(--font1) !important;
    font-family: "Pretendard-Regular";
}
.ant-popconfirm-buttons {
    text-align: center !important;
}
.ant-popconfirm-buttons button {
    height: 32px !important;
    display: inline-block;
    line-height: normal !important;
}
.ant-popconfirm-buttons .ant-btn-default {
    background: var(--secondary);
    color: var(--point) !important;
    border: none !important;
}
.ant-popconfirm-buttons button:first-of-type {
    margin: 0;
}

/* PAGINATION */
.ant-pagination-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.ant-pagination-item a {
    color: var(--font2) !important;
}
.ant-pagination-item-active {
    border: 1px solid var(--point) !important;
    background: transparent !important;
}
.ant-pagination-item-active a {
    font-family: "Pretendard-Regular";
    color: var(--point) !important;
}
.ant-pagination-total-text,
.ant-pagination-item-link svg {
    font-family: "Pretendard-Regular";
    color: var(--font2);
}

/* TAG & BADGE */
.errorTag {
    color: white;
    border-radius: 50px;
    border: none;
    background-color: var(--danger);
}
.warningTag {
    color: var(--font1);
    border-radius: 50px;
    border: none;
    background-color: var(--warning);
}
.normalTag {
    color: white;
    border-radius: 50px;
    border: none;
    background-color: var(--line1);
}
.pointTag {
    color: white;
    border-radius: 50px;
    border: none;
    background-color: var(--point);
    margin: 0;
}
.ant-badge-dot {
    width: 5px !important;
    min-width: 5px !important;
    height: 5px !important;
    top: 2px !important;
    transform-origin: 102% 0% !important;
}

/* Drawer */
.ant-drawer {
    animation: appear1 1s;
    display: block;
    position: absolute;
    align-self: end;
    border: transparent !important;
    outline: transparent !important;
}
.ant-drawer-header {
    padding: 10px;
    border: none;
}
@keyframes appear1 {
    0% {
        height: 30px !important;
    }
    100% {
        height: 100%;
    }
}
.ant-drawer:has(.ant-drawer-content-wrapper-hidden),
.ant-drawer:has(.drawerClosed) {
    animation: hide1 1s;
    bottom: 0;
}
@keyframes hide1 {
    0% {
        height: 50%;
    }
    100% {
        height: 30px;
    }
}
.ant-drawer-content {
    background: #fafafa;
    box-shadow: none;
    border-radius: 8px;
    overflow: hidden !important;
}
.ant-drawer-content > div > div {
    overflow: hidden !important;
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
    animation: appear 1s;
    animation-fill-mode: forwards;
    box-shadow: none;
}
@keyframes appear2 {
    0% {
        max-height: 0%;
    }
    100% {
        max-height: 100%;
    }
}
.ant-drawer-bottom > .ant-drawer-content-wrapper:has(.drawerClosed),
.ant-drawer-bottom > .ant-drawer-content-wrapper-hidden {
    animation: hide2 0.5s;
    animation-fill-mode: forwards;
    display: block;
}
@keyframes hide2 {
    0% {
        max-height: 100%;
    }
    100% {
        max-height: 0;
    }
}
.ant-drawer-bottom > .ant-drawer-content-wrapper:has(.drawerClosed) button svg,
.ant-drawer-bottom > .ant-drawer-content-wrapper-hidden button svg {
    animation: rotate 2s ease-in-out;
    /* animation-fill-mode: forwards; */
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.ant-divider-horizontal.ant-divider-with-text {
    font-size: 0.938rem;
    margin: 10px 0;
}

/* UPLOAD */
.ant-upload-wrapper {
    padding: 0 !important;
}
.ant-upload {
    display: block;
    padding: 0 !important;
}
.ant-upload-list-item {
    padding-left: 16px;
    cursor: pointer;
}
.ant-upload-list-item span {
    font-size: 1.063rem;
}

/* MAP */
#map > div {
    cursor: default !important;
}
#map button {
    min-width: auto !important;
}

/* ETC */
.skeleton-block {
    width: 100%;
    height: 100%;
}
.skeleton-block h3,
.skeleton-block .ant-skeleton-image {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    margin: 0;
}
.skeleton-block ul {
    display: none;
}

.ant-spin-dot-item {
    background: var(--point);
}

.ant-empty-image,
.ant-empty-description {
    color: var(--font3) !important;
}
.ant-empty-image ellipse {
    opacity: 0.5;
}
.ant-empty-description {
    font-family: "Pretendard-Regular";
}

.ant-progress-steps-item {
    width: 4px !important;
    height: 10px !important;
    border-radius: 1px;
}
.ant-progress-text {
    font-size: 1.063rem !important;
    margin: 2px !important;
}

.ant-notification-notice-message,
.ant-notification-notice-description {
    font-size: 1.063rem !important;
    white-space: pre-wrap;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.table-row-selected {
    background: rgba(24, 116, 255, 0.08);
    font-weight: bold;
}
.table-row-warning {
    background-color: #f5dddf;
    font-weight: bold;
}

.rc-virtual-list-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

@media only screen and (max-width: 800px) {
    body {
        background-repeat: no-repeat;
        -webkit-touch-callout: none;
    }
    .ant-layout:has(.loginPage) {
        background-image: url(./assets/images/background_mobile.png);
        background-size: cover;
        background-position: center;
    }
    img {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
    .pageWrapper {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 0;
        background-color: transparent;
    }
    .titleWrapper {
        height: fit-content;
    }

    /* BUTTON */
    button:not(
            .ant-picker-header-super-prev-btn,
            .ant-picker-header-prev-btn,
            .ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn,
            .ant-picker-month-btn,
            .ant-picker-year-btn,
            .ant-picker-decade-btn,
            .ant-pagination-item-link,
            .ant-btn-icon-only,
            .notBtn,
            .react-datepicker__navigation
        ) {
        min-width: auto;
        height: 50px;
        border-radius: 8px;
    }
    .btn-text {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .ant-select-clear {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .ant-modal-confirm-btns button {
        min-width: calc((100% - 8px) / 2);
    }

    /* INPUT & SELECT */
    input::placeholder {
        color: var(--font3) !important;
    }
    .ant-picker,
    .ant-select,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .adm-search-bar {
        width: 100%;
        height: 50px;
        min-height: 50px;
    }
    .ant-input:not(:focus, .disabled-input),
    .ant-picker,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .adm-search-bar {
        border: none !important;
        border-radius: 8px !important;
    }
    .ant-input:not(:focus, .disabled-input) {
        background-color: var(--background);
    }
    .ant-picker,
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .adm-search-bar {
        background-color: var(--background) !important;
    }
    .ant-input:focus {
        background-color: var(--background);
    }
    input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled,
    .ant-select-selector:has(input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled),
    .locked-selector,
    .locked-selector > div {
        color: var(--font2) !important;
        background-color: #e6ebf3 !important;
        position: relative;
        border-radius: 8px;
    }
    input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled::placeholder,
    .ant-select-selector:has(input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled)::placeholder,
    .locked-selector span {
        color: var(--font2) !important;
    }
    input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled::before,
    .ant-select-selector:has(input:not(.disabled-input, .disabled-datepicker input, .disabled-selector input):disabled)::before,
    .locked-selector > div::before {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 11px;
        top: 16px;
        content: "";
        background-size: cover;
        background-image: url(./assets/images/icon/icon_slash.png);
    }
    .ant-input-affix-wrapper:hover,
    .ant-select-selector:hover {
        border: none !important;
    }
    .ant-input-affix-wrapper:focus-within,
    .ant-select-selector:focus-within {
        border: 1.5px solid var(--point) !important;
    }
    .allowClearSelect .ant-select-arrow {
        opacity: 0;
    }
    .allowClearSelect .ant-select-clear {
        opacity: 1;
    }
    .disabled-input,
    .disabled-selector,
    .disabled-selector > div,
    .disabled-selector span,
    .disabled-selector > div:hover,
    .disabled-datepicker,
    .disabled-datepicker > div,
    .disabled-datepicker input {
        padding: 0 !important;
        justify-content: end;
        text-align: right;
        background-color: transparent !important;
    }
    .disabled-selector,
    .disabled-selector .ant-select-selector {
        height: auto !important;
        min-height: 38px !important;
    }
    .disabled-datepicker {
        background-color: transparent !important;
    }
    .disabled-selector span:has(svg),
    .locked-selector svg,
    .disabled-datepicker .ant-picker-suffix {
        display: none !important;
    }
    .disabled-selector span {
        width: 100%;
        text-align: right;
        color: var(--font1) !important;
    }
    .disabled-radio {
        padding: 0;
    }
    .disabled-radio label {
        margin: 0;
    }

    .locked-selector > div {
        pointer-events: none;
    }

    .ant-upload button {
        height: fit-content;
    }
    .ant-upload-list {
        min-height: 50px;
        border: 1px solid #e1e7ef !important;
        border-radius: 8px;
        text-align: left;
        padding: 8px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        justify-content: center;
    }
    .ant-upload-list-item {
        margin-top: 0 !important;
    }
    .ant-upload-list-item-action {
        opacity: 1 !important;
    }
    .ant-upload-list-item-actions svg {
        color: var(--danger) !important;
    }
    .ant-upload-wrapper {
        height: fit-content !important;
        display: flex;
        flex-direction: column;
    }
    .disabled-datepicker input::placeholder,
    .disabled-selector .ant-select-selection-placeholder,
    .disabled-input::placeholder {
        display: block !important;
        color: var(--font1) !important;
    }

    /* CHECKBOX & RADIO */
    .iconCheckbox {
        border: solid 1px #dde2ee;
        box-shadow: none;
        padding: 0 2px;
    }
    .iconCheckbox > span:last-of-type {
        height: 100%;
    }
    .iconCheckbox:hover {
        box-shadow: none;
    }
    .iconCheckbox:active {
        box-shadow: none;
    }
    .iconCheckbox:has(.ant-checkbox-checked) {
        border: 1px solid var(--point);
    }

    /* TABS */
    .ant-tabs-nav-list {
        max-width: calc(100vw - 32px);
        overflow-x: auto;
    }
    .ant-tabs-nav-list::-webkit-scrollbar {
        display: none;
    }
    .ant-tabs {
        height: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }
    .ant-tabs-tab {
        width: 100%;
        min-height: 40px;
        background-color: transparent !important;
        box-shadow: none;
        opacity: 0.5;
    }
    .ant-tabs-nav {
        max-height: 40px;
    }
    .ant-tabs-tab-active {
        opacity: 1;
        background-color: white !important;
    }
    .ant-tabs-tab > div {
        font-family: "Pretendard-Regular";
        font-size: 1.063rem;
        color: var(--font1);
    }
    .ant-tabs-tab-active > div {
        font-family: "Pretendard-Bold";
        color: var(--point) !important;
    }
    .ant-tabs-content-holder {
        width: 100vw;
        max-height: calc(var(--vh, 1vh) * 100 - 164px);
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        padding: 24px 16px 24px 16px;
        margin-left: -16px;
        background-color: transparent;
        border-radius: 0;
    }
    .maintenanceTab .ant-tabs-content-holder {
        max-height: calc(var(--vh, 1vh) * 100 - 272px);
    }
    .fullTabContentHolder .ant-tabs-content-holder {
        padding: 0;
        overflow: hidden;
    }
    .ant-tabs-content-holder::-webkit-scrollbar {
        width: 6px;
    }
    .ant-tabs-content-holder::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

    .ant-tabs-ink-bar {
        visibility: visible !important;
    }

    /* TABLE */
    table {
        width: 100%;
    }

    /* MODAL */
    .ant-modal:not(.custom-modal),
    .ant-modal-confirm-confirm {
        width: calc(100vw - 32px) !important;
    }

    /* MENU */
    .ant-menu {
        max-height: none !important;
        border-inline-end: none !important;
    }
    .ant-menu::before {
        display: none;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
        padding: 24px 0 !important;
    }
    .ant-menu-item-active p {
        color: var(--font1) !important;
    }
    .ant-menu-inline .ant-menu-sub.ant-menu-inline li {
        padding-left: 46px !important;
    }
    .ant-menu-item-only-child a,
    .ant-menu-title-content div {
        margin: 0 !important;
    }

    .adm-tab-bar-item img {
        filter: invert(50%) sepia(5%) saturate(7%) hue-rotate(323deg) brightness(89%) contrast(88%);
    }
    .adm-tab-bar-item-active img {
        filter: invert(41%) sepia(76%) saturate(5072%) hue-rotate(209deg) brightness(101%) contrast(101%);
    }

    .adm-tab-bar-item-title {
        font-family: "Pretendard-Bold", sans-serif;
        color: var(--font2);
        font-size: 0.938rem !important;
    }
    .adm-tab-bar-item-active .adm-tab-bar-item-title {
        color: var(--point) !important;
    }

    /* FLOATING PANEL */
    .adm-floating-panel {
        z-index: 9999;
        box-shadow: 0px -2px 6px -1px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
    }
    .adm-floating-panel-bar {
        width: 32px !important;
    }
    .fixedFloatingPanel .adm-floating-panel-header {
        display: none !important;
    }
    .fixedFloatingPanel .adm-floating-panel-content {
        border-top-right-radius: 16px !important;
        border-top-left-radius: 16px !important;
    }

    /* CALENDARPICKER*/
    .adm-popup-body:has(.adm-calendar-picker-view) {
        width: 100%;
        max-width: calc(100vw - 32px);
        min-height: fit-content !important;
        margin: 16px;
        border-radius: 16px;
        box-shadow: 0px -2px 6px -1px rgba(0, 0, 0, 0.08);
    }
    .adm-calendar-picker-view-header {
        padding: 16px !important;
    }
    .adm-calendar-picker-view-cells {
        padding: 8px 16px !important;
    }
    .adm-calendar-picker-footer-bottom {
        padding: 0px 16px 16px 16px !important;
    }
    .adm-calendar-picker-view-header svg {
        color: var(--font3);
    }
    .adm-calendar-picker-view-title {
        font-family: "Pretendard-Regular", sans-serif;
        color: var(--font1);
        font-size: 1.063rem !important;
    }
    .adm-calendar-picker-view-cell-date {
        font-family: "Pretendard-Regular", sans-serif;
        font-size: 0.938rem !important;
    }
    .adm-calendar-picker-view-body {
        height: fit-content !important;
        max-height: 310px;
        overflow: hidden !important;
    }
    .adm-calendar-picker-view-body > div {
        height: 310px !important;
    }
    .adm-calendar-picker-footer .adm-divider,
    .adm-calendar-picker-view-cell-top,
    .adm-calendar-picker-view-cell-bottom,
    .adm-calendar-picker-view-body .adm-calendar-picker-view-title,
    .adm-calendar-picker-view-mark,
    .adm-popup-close-icon {
        display: none !important;
    }
    .adm-calendar-picker-view-cell {
        height: 45px !important;
        min-height: auto !important;
    }
    .adm-calendar-picker-view-cell:not(:has(div)) {
        height: 0px !important;
    }
    .adm-button,
    .adm-calendar-picker-view-cell-selected-begin,
    .adm-calendar-picker-view-cell-selected-end {
        background-color: var(--point) !important;
    }
    .adm-calendar-picker-view-cell-selected-begin {
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
    }
    .adm-calendar-picker-view-cell-selected-end {
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
    }
    .disabledRangePicker + .adm-calendar-picker-footer button {
        opacity: 0.5;
        filter: saturate(80%);
        pointer-events: none;
    }
    .disabledRangePickerText {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 70px;
    }

    /* ETC */
    body:has(.adm-floating-panel) .adm-tab-bar {
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
        z-index: 901;
    }
    body:has(.fullPanel) .ant-layout-content {
        z-index: 1;
    }
    body:has(.fullPanel) .adm-tab-bar {
        z-index: 0;
    }
    .ant-progress-inner {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) inset;
    }
    .dashboardMap > div:last-of-type {
        left: -14px !important;
        top: 78px !important;
    }
    .customDivider {
        width: calc(100vw + 10px);
        position: relative;
        border: 6px solid #f6f6f6;
        margin: 24px 0;
        left: -16px;
    }
    .ant-notification-notice-description {
        font-size: 0.938rem !important;
    }
    .ant-pagination > li {
        margin: 0 !important;
    }
    .ant-pagination-simple-pager input {
        width: 50px;
        font-size: 14px !important;
    }
    .adm-mask {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .skeleton-block {
        width: 100vw;
        height: 100vh;
    }
}
