.menuWrapper {
    display: flex;
    height: calc(100vh - 48px);
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    background: var(--content1);
    justify-content: space-between;
    padding: 24px 0;
    position: relative;
    border-radius: 8px;
}
.bottomLogoWrapper {
    width: 100%;
    position: absolute;
    bottom: 16px;
}
.bottomLogoWrapper > div:not(:last-of-type) {
    width: 100%;
}
.userWrapper,
.userWrapper div {
    margin: 0 !important;
}

/* WEATHER */
.weatherCarouselWrapper {
    width: calc(100% - 32px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    margin: 0 16px;
    border-radius: 8px;
    background-image: url(../../assets/images/weather/bg_day.png);
    background-repeat: no-repeat;
    background-size: 250%;
    animation: backgroundChange 60s infinite alternate;
}
.weatherCarouselWrapperNight {
    background-image: url(../../assets/images/weather/bg_night.png);
}
@keyframes backgroundChange {
    0% {
        background-position: left top;
    }
    100% {
        background-position: right bottom;
    }
}
.weatherCarouselWrapper .weatherId {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.weatherCarouselWrapper .weatherId .weatherCrop {
    width: clamp(50px, 2.5vw, 80px);
    height: clamp(50px, 2.5vw, 80px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.weatherCarouselWrapper .weatherId img {
    width: clamp(70px, 3.5vw, 100px);
    height: clamp(70px, 3.5vw, 100px);
    object-fit: fill;
    object-position: center;
}
.weatherCarouselWrapper span:not(:has(svg)),
.weatherCarouselWrapper h4 {
    width: fit-content;
    font-family: "Pretendard-Bold";
    color: #595959;
    text-shadow: -1px 0px white, 0px 1px white, 1px 0px white, 0px -1px white;
    z-index: 1;
    margin: 0;
}
.weatherCarouselWrapper h4 {
    line-height: 18px;
}
.weatherCarouselWrapper li button {
    min-width: 16px !important;
}
.weatherCarouselWrapper .weatherTime {
    display: block;
    width: 100%;
    text-align: center;
}
@media (min-width: 1800px) {
    .weatherCarouselWrapper span:not(:has(svg)) {
        font-size: 1.063rem;
    }
    .weatherCarouselWrapper h4 {
        font-size: 22px;
    }
}
