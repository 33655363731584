.left {
    width: 100%;
    height: 100vh;
    background-color: var(--content1);
    border-right: 20px solid var(--content2);
    border-radius: 0 80px 80px 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.left .loginCard {
    width: 410px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.loginCard > div {
    width: 100%;
    margin-bottom: 0;
}
.loginInput {
    height: 50px !important;
    padding: 8px 16px;
    margin-top: 4px;
}
.loginCheckbox span span {
    width: 18px !important;
    height: 18px !important;
}
.loginBtn {
    display: flex;
    height: 54px !important;
    font-size: 1.188rem;
}

.copyright {
    color: var(--font2);
    position: absolute;
    bottom: 24px;
}

.right {
    height: 100vh;
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 150px;
}

.logo {
    -webkit-animation: text-focus-in 4s forwards;
    -webkit-animation-delay: 2s;
    animation: text-focus-in 4s forwards;
    animation-delay: 2s;
    opacity: 0;
}
@-webkit-keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
        animation-timing-function: ease-out;
    }
}
@keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
        animation-timing-function: ease-out;
    }
}

/* MOBILE */
@media only screen and (max-width: 800px) {
    .loginWrapper {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
    }
    .left {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        justify-content: start;
    }
    .right {
        display: none;
    }
    .loginCard {
        width: 100% !important;
    }
    .loginCard > div > div > div > div > div > div > span:first-of-type {
        font-family: "Pretendard-Regular" !important;
    }
    .loginInput {
        height: 50px !important;
    }
    .loginInput,
    .loginInput input {
        background-color: var(--content1) !important;
    }
    .loginCheckbox span span {
        border-radius: 50px !important;
    }
    .loginCard h2,
    .copyright {
        display: none;
    }
    .loginBtn {
        width: calc(100vw - 32px) !important;
        height: 50px !important;
        min-width: auto !important;
        position: fixed;
        left: 16px;
        right: 16px;
        bottom: 16px;
    }
}
