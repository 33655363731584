.pageWrapper {
    width: 100vw;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.result h2 {
    margin: 0;
}
