.cardWrapper {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--background);
}
.cardWrapper svg {
    color: var(--font3);
}
.titleWrapper {
    display: flex;
    justify-content: space-between;
}
.titleWrapper svg {
    font-size: 16px;
}
.contentWrapper {
    width: 100%;
    display: flex;
    gap: 8px;
    overflow-x: auto;
}
.contentWrapper::-webkit-scrollbar {
    display: none;
}
.contentSpace {
    display: flex;
    gap: 4px;
    align-items: center;
}
.contentSpace svg {
    font-size: 12px;
}
.contentSpace span {
    white-space: nowrap;
}
